<template>
  <b-button class="call-me-back-button" @click="openCallMeBackModal">
    <div>
      <b-icon icon="phone-alt" />
      <label class="ml-1 call-me-back-label">{{
        $t("af:call_me_back.button.open_modal")
      }}</label>
    </div>
  </b-button>
</template>

<script>
import eventBus, { EVENTS } from "@/utils/eventBus";
import { CALL_ME_BACK_TYPE } from "@/utils/const";

export default {
  name: "CallMeBackButton",
  methods: {
    openCallMeBackModal() {
      eventBus.$emit(EVENTS.OPEN_CALL_ME_BACK_MODAL, CALL_ME_BACK_TYPE.CBR_BTN);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";

@media only screen and (max-width: 768px) {
  .call-me-back-button {
    display: none;
  }

  .mbh {
    .call-me-back-button {
      display: block;
      padding: 8px 24px !important;
    }
  }
}

.call-me-back-button {
  position: fixed;
  top: 12.5rem;
  right: -147px;
  background-color: $grey-dark;
  border-color: $grey-dark;
  border-radius: 15px 0 0 15px;
  color: $white;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  transition: right 0.5s;
  font-weight: bold;
  font-size: 16px;
  z-index: 9999;

  &:hover {
    right: 0;
  }
}

.call-me-back-label {
  cursor: pointer;
}

.mbh {
  .call-me-back-button {
    right: -165px;

    &:hover {
      right: -19px;
    }
  }
}
</style>
